export const getDummyQuestionPracticeSkill = function (question_code, practice_id) {
    return {
        question_code,
        practice_skill_id: '',
        dok: null,
        skill: '',
        example_solution: '',
        correct_answer: null,
        points: [
            {
                id: -1,
                point: 0,
                description: '',
                example_responses: null
            },
            {
                id: -2,
                point: 1,
                description: '',
                example_responses: null
            }
        ],
        questions: null,
        domain: null,
        practice_id
    }
}
export const isNumeric = function (num) {
    return (num.toString() === parseFloat(num).toString());
}
